import React from "react";

interface JobAlertAdProps {
  className?: string;
}

export const JobAlertAd = ({ className }: JobAlertAdProps) => {
  return (
    <div className={className}>
      <a
        target="_blank"
        href="https://nextleveljobs.eu?ref=felixgerschau.com"
        className="p-4 block cursor-pointer rounded-lg bg-gray-600 hover:scale-105 transform transition-all duration-200 ease-in-out relative"
      >
        <h6 className="text-xl font-bold tracking-tight text-white">
          <span className="text-lime-400">
            Get notified
            <span className="absolute top-0 left-0 w-5 h-5 flex -ml-2 -mt-2 -z-10 border-1 sm:border-2 border-solid border-lime-100 rounded-full bg-lime-500"></span>
          </span>{" "}
          about opportunities at <i>top tech companies</i>
        </h6>
      </a>
    </div>
  );
};
