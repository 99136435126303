import { Link } from 'gatsby';
import React from 'react';
import { IPostEdge } from '../../../dto/IPost';

interface PopularPostsProps {
  posts: IPostEdge[];
  className?: string;
}

export const PopularPosts = ({ posts, className }: PopularPostsProps) => {
  return (
    <section style={{ paddingTop: '100vh' }} className={`${className} flex flex-col text-gray-200`}>
      <h2 className="text-sm text-gray-200 tracking-wider mb-4">Popular Posts</h2>
      <ul className="flex text-sm flex-col space-y-2">
        {posts?.map((popularPost) => (
          <Link className="text-gray-400 hover:text-gray-300" to={popularPost.node.fields.slug}>
            <li className="">{popularPost.node.frontmatter.title}</li>
          </Link>
        ))}
      </ul>
    </section>
  );
};
