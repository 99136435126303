import { useLayoutEffect, useRef, useState } from 'react';

// from https://nickymeuleman.netlify.app/blog/table-of-contents
export function useActiveId(itemIds: string[]) {
  const [activeId, setActiveId] = useState(``);
  const items = useRef<Element[]>([]);

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            entry.target.id &&
            ['H1', 'H2', 'H3'].includes(entry.target.tagName)
          ) {
            setActiveId(entry.target.id);
          }
        });
      },
      { rootMargin: `0% 0% -70% 0%` },
    );

    itemIds.forEach((id) => {
      const item = document.getElementById(id);
      observer.observe(item);
      items.current.push(item);
    });

    return () => {
      items.current.forEach((item) => {
        observer.unobserve(item);
      });
    };
  }, [itemIds]);

  return activeId;
}
