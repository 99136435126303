import React from 'react';
import { List } from '../../../components/List';
import { IPostEdge } from '../../../dto/IPost';

interface GuideSidebarProps {
  posts: IPostEdge[];
  title: string;
  slug: string;
  className?: string;
}

export const GuideSidebar = ({ posts, title, className, slug }: GuideSidebarProps) => {
  if (!posts.length) return null;
  return (
    <nav className={`${className}`}>
      <h2 className="text-gray-200 text-sm tracking-wider mb-4">{title}</h2>
      <List
        items={posts.map(({ node: post }) => ({
          title: post.frontmatter.title,
          link: post.fields.slug,
          active: post.fields.slug === slug,
        }))}
      />
    </nav>
  );
};
