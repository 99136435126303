import React from 'react';
import { ITableOfContents, TOCItem } from '../../../dto/IPost';
import { useActiveId } from '../../../helpers/useActiveId';
import styles from './tableOfContents.module.scss';

function getIds(items: TOCItem[]) {
  return items?.reduce((acc, item) => {
    if (item.url) {
      // url has a # as first character, remove it to get the raw CSS-id
      acc.push(item.url.slice(1));
    }
    if (item.items) {
      acc.push(...getIds(item.items));
    }
    return acc;
  }, []);
}

const isActiveId = (id: string, url?: string) => id === url?.slice(1);
const getActiveClassName = (id: string, url: string) =>
  isActiveId(id, url) ? 'text-blue-400 hover:text-blue-300' : 'text-gray-400 hover:text-gray-300';

export const TableOfContents = ({ tableOfContents }: { tableOfContents: ITableOfContents }) => {
  const ids = getIds(tableOfContents.items);
  const activeId = useActiveId(ids);

  return (
    <nav
      className={`max-h-screen py-12 -mt-12 pr-4 xl:pr-0 overflow-y-auto col-span-1 top-0 right-0  h-fit ${styles.toc}`}
    >
      <h2 className="text-sm text-gray-200 tracking-wider mb-4">On this page</h2>
      <ul className="space-y-2">
        {tableOfContents?.items?.map(
          (item) =>
            item.title && (
              <li className="text-sm font-medium">
                <a className={`block  ${getActiveClassName(activeId, item.url)}`} href={item.url}>
                  {item.title}
                </a>
                {!!item.items?.length && (
                  <ul>
                    {item.items.map((subItem) => (
                      <li
                        className={`pl-2 font-light mt-2 ${getActiveClassName(
                          activeId,
                          subItem.url,
                        )}`}
                      >
                        <a href={subItem.url} className="block">
                          {subItem.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ),
        )}
      </ul>
    </nav>
  );
};
