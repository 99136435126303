import React from 'react';

export const Separator = ({ inverted, className }: { inverted?: boolean; className?: string }) => {
  return (
    <div className={`w-full ${inverted ? 'bg-slate-800' : 'bg-slate-900'} ${className || ''}`}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200">
        <path
          className={`${inverted ? 'fill-slate-900' : 'fill-slate-800'}`}
          fill-opacity="1"
          d="M0,64L80,85.3C160,107,320,149,480,149.3C640,149,800,107,960,101.3C1120,96,1280,128,1360,144L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        ></path>
      </svg>
    </div>
  );
};
