import { MDXProvider, MDXProviderComponentsProp } from "@mdx-js/react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import { Layout } from "../../components/Layout";
import { IPostEdge, IPostNode } from "../../dto/IPost";
import { Separator } from "../../components/Separator";
import styles from "./post.module.scss";
import { TableOfContents } from "./TableOfContents";
import { GuideSidebar } from "./GuideSidebar";
import { PopularPosts } from "./PopularPosts/PopularPosts";
import SEO from "../../components/SEO";
import "prism-solarized-dark/prism-solarizeddark.css";
import { JobAlertAd } from "../../components/JobAlertAd";

const components: MDXProviderComponentsProp = {
  a: (props) => (
    <a {...props} className={`${props.className} hover:underline`}>
      {props.children}
    </a>
  ),
};

interface PostProps {
  data: {
    post: IPostNode;
    popularPosts: {
      edges: IPostEdge[];
    };
    nextPost: IPostNode;
    prevPost: IPostNode;
    guidePosts: {
      edges: IPostEdge[];
    };
  };
  pageContext: {
    slug: string;
    nextslug: string;
    prevslug: string;
  };
}

export default (props: PostProps) => {
  const { data, pageContext } = props;
  const { slug } = pageContext;
  const postNode = data?.post;
  const post = postNode?.frontmatter;
  if (!post?.id) {
    post.id = slug;
  }
  const {
    popularPosts: { edges: popularPosts },
    guidePosts: { edges: guidePosts },
  } = data;

  return (
    <Layout comments>
      <SEO postPath={slug} postNode={postNode} postSEO />
      {/*<script async src="https://media.ethicalads.io/media/client/ethicalads.min.js"></script>*/}
      <main className={`m-auto flex-grow ${styles.postWrapper}`}>
        <div className="text-lg text-center max-w-prose mx-auto pt-32 pb-8 px-4 md:px-0">
          <h1 className="flex gap-2 flex-col">
            <span className="flex m-auto gap-2 text-base text-center text-blue-500 font-semibold tracking-wide uppercase">
              {post.tags?.map((tag) => (
                <Link className="hover:underline" to={`/tags/${tag}`}>
                  {tag}
                </Link>
              ))}
            </span>
            <span className="block text-3xl leading-8 font-extrabold tracking-tight text-slate-100 sm:text-4xl">
              {post.title}
            </span>
          </h1>
          <p className="mt-8 text-xl text-slate-400 leading-8">
            {post.description}
          </p>
        </div>
        <Separator />
        <div className="flex bg-slate-900 pb-20 w-full">
          <div
            className={`mx-auto flex flex-col xl:flex-row flex-wrap xl:flex-nowrap xl:flex-column lg:px-6 pt-12 gap-8 xl:gap-16 w-full max-w-8xl`}
          >
            <aside
              className={`hidden xl:flex m-auto px-4 max-w-xs md:px-0 xl:m-0 w-full xl:w-fit xl:pl-0 flex-shrink`}
              style={{ width: "320px" }}
            >
              <div className="flex flex-col sticky pt-12 -mt-12 top-0 h-fit content-box gap-12">
                <GuideSidebar
                  className="mr-auto"
                  posts={guidePosts}
                  title={postNode.frontmatter.guidetitle}
                  slug={postNode.fields.slug}
                />
                <div
                  className="dark raised"
                  data-ea-publisher="felixgerschaucom"
                  id="sidebar-article"
                  data-ea-type="image"
                ></div>
                {!guidePosts?.length && <PopularPosts posts={popularPosts} />}
              </div>
            </aside>
            <GuideSidebar
              className="block xl:hidden mr-auto mx-auto px-4 py-16"
              posts={guidePosts}
              title={postNode.frontmatter.guidetitle}
              slug={postNode.fields.slug}
            />
            <article className={`article article-width lg:-mt-2`}>
              <MDXProvider components={components}>
                <MDXRenderer>{postNode.body}</MDXRenderer>
              </MDXProvider>
            </article>
            <aside
              style={{ width: "320px" }}
              className={`hidden xl:block max-w-xs`}
            >
              <div className="sticky top-0 h-fit">
                {!!postNode?.tableOfContents?.items?.length && (
                  <TableOfContents tableOfContents={postNode.tableOfContents} />
                )}
                <JobAlertAd className="mb-8" />
                <div className="pt-96">
                  <h3
                    style={{ paddingTop: "100vh" }}
                    className="text-base text-slate-400"
                  >
                    Last updated
                  </h3>
                  <p className="text-xl text-gray-200 font-bold">
                    {new Date(post.updated || post.date)?.toLocaleDateString(
                      "en-US",
                      {
                        dateStyle: "long",
                      }
                    )}
                  </p>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </main>
      <Separator inverted />
    </Layout>
  );
};

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $prevslug: String
    $nextslug: String
    $toc: [String]
    $currentDate: Date
  ) {
    post: mdx(fields: { slug: { eq: $slug } }) {
      body
      timeToRead
      excerpt
      fields {
        slug
        date
      }
      tableOfContents
      frontmatter {
        title
        subtitle
        cover {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
        date
        updated
        category
        tags
        description
        guidetitle
        toc
        canonicalUrl
        hideFeedback
      }
    }
    prevPost: mdx(frontmatter: { slug: { eq: $prevslug, ne: null } }) {
      body
      timeToRead
      excerpt
      fields {
        slug
        date
      }
      frontmatter {
        title
        subtitle
        cover {
          childImageSharp {
            gatsbyImageData(width: 350)
          }
        }
        date
        category
        tags
        description
        guidetitle
        canonicalUrl
        hideFeedback
      }
    }
    nextPost: mdx(frontmatter: { slug: { eq: $nextslug, ne: null } }) {
      body
      timeToRead
      excerpt
      fields {
        slug
        date
      }
      frontmatter {
        title
        subtitle
        cover {
          childImageSharp {
            gatsbyImageData(width: 350)
          }
        }
        date
        category
        tags
        description
        guidetitle
        canonicalUrl
        hideFeedback
      }
    }
    popularPosts: allMdx(
      filter: {
        frontmatter: {
          internaltags: { in: "popular" }
          date: { lte: $currentDate }
        }
      }
      sort: { fields: [fields___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          ...postNodeFragment
        }
      }
    }
    guidePosts: allMdx(filter: { frontmatter: { slug: { in: $toc } } }) {
      edges {
        node {
          frontmatter {
            title
            position
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
